import { FC, useRef, useState } from "react";
import styles from "./DebuggingControls.module.scss";
import { Button } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faPause, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";

interface DebuggingControlsProps {
    isPlaying: boolean;
    isLoading: boolean;
    onBack: () => void;
    onPause: () => () => void;
    onStop: () => void;
}

const DebuggingControls: FC<DebuggingControlsProps> = (props) => {
    const resume_handler_ref = useRef<{ resume: undefined | (() => void) }>({ resume: undefined });
    const [paused, setPaused] = useState(false);

    const pauseHandler = () => {
        setPaused(true);
        const resume = props.onPause();

        resume_handler_ref.current.resume = () => {
            setPaused(false);
            resume();
        };
    };
    return (
        <div className={styles.DebuggingControls}>
            {/* <Button onPress={props.onBack} startContent={<FontAwesomeIcon icon={faBackward} />}>
                Back
            </Button> */}
            {!paused && !props.isLoading && (
                <Button onPress={pauseHandler} startContent={<FontAwesomeIcon icon={faPause} />}>
                    Pause
                </Button>
            )}
            {paused && !props.isLoading && (
                <Button onPress={resume_handler_ref.current.resume} startContent={<FontAwesomeIcon icon={faPlay} />}>
                    Play
                </Button>
            )}
            <Button onPress={props.onStop} startContent={<FontAwesomeIcon icon={faStop} />}>
                Stop
            </Button>
            {/* {!props.isPlaying && <Button onClick={props.onClear}>Clear</Button>} */}

            {/* <Button>Forward</Button> */}
        </div>
    );
};

export default DebuggingControls;
