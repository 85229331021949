import { FC, useEffect, useState } from "react";
import styles from "./TestCases.module.scss";
// import Button from "../UI/Button/Button";
import { TestCase } from "../../types/types";
// import { ColorRing } from "react-loader-spinner";
import { Button, Tabs, Tab } from "@nextui-org/react";

import ReactDOM from "react-dom";
import { Problem } from "../../problems/interfaces";

interface TestCasesProps {
    selectedProblem: Problem;
    onDebug: (inputs: TestCase) => void;
    isDebugging: boolean;
    isDebugLoading: boolean;
}

const TestCases: FC<TestCasesProps> = (props) => {
    const [shownTestCase, setShownTestCase] = useState<TestCase>(props.selectedProblem.test_cases[0]);
    const [debuggingTestCase, setDebuggingTestCase] = useState<TestCase | undefined>(undefined);

    useEffect(() => {
        setShownTestCase(props.selectedProblem.test_cases[0]);
    }, [props.selectedProblem]);

    const debugHandler = (testCase: TestCase) => {
        setDebuggingTestCase(testCase);
        props.onDebug(testCase);
    };

    useEffect(() => {
        if (!props.isDebugging) setDebuggingTestCase(undefined);
    }, [props.isDebugging]);

    return (
        <div className={styles.TestCases}>
            <div className={styles.TopPart}>
                <div className={styles.Buttons}>
                    <Tabs
                        aria-label="Tabs variants"
                        variant={"light"}
                        selectedKey={`${shownTestCase.id}`}
                        onSelectionChange={(testCaseId) =>
                            setShownTestCase(
                                props.selectedProblem.test_cases.find(
                                    (testCase) => `${testCase.id}` === `${testCaseId}`,
                                )!,
                            )
                        }
                    >
                        {props.selectedProblem.test_cases.map((testCase) => (
                            <Tab key={`${testCase.id}`} title={`Case ${testCase.id}`} />
                        ))}
                    </Tabs>

                    {/* // {props.selectedProblem.test_cases.map((testCase) => (
                    //     <Button
                    //         className={shownTestCase.id === testCase.id ? styles.Selected : ""}
                    //         onPress={() => setShownTestCase(testCase)}
                    //     >
                    //         Case {testCase.id}
                    //     </Button>
                    // ))} */}
                </div>
                <div>
                    {!props.isDebugging &&
                        ReactDOM.createPortal(
                            <Button className={styles.DebugButton} onPress={() => debugHandler(shownTestCase)}>
                                Visualize Case {shownTestCase.id}
                            </Button>,
                            document.getElementById("visualize-button-root")!,
                        )}

                    {/* {!props.isDebugging && (
                        
                    )} */}
                    {/* {props.isDebugLoading && (
                        <p className={styles.DebuggingText}>
                            <ColorRing
                                visible={true}
                                height="28.7"
                                width="28.7"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={["black", "black", "black", "black", "black"]}
                            />
                        </p>
                    )} */}
                    {props.isDebugging && !props.isDebugLoading && (
                        <p className={styles.DebuggingText}>
                            Debugging
                            {debuggingTestCase !== undefined &&
                                shownTestCase !== debuggingTestCase &&
                                ` Case ${debuggingTestCase.id}`}
                        </p>
                    )}
                </div>
            </div>

            <div className={styles.TestCase}>
                <p className={styles.SectionHeading}>Input</p>
                <pre>
                    {Object.entries(shownTestCase.inputs || {}).map(
                        ([key, value]) => `${key} = ${JSON.stringify(value)}\n`,
                    )}
                </pre>

                {/* <Visualizer></Visualizer> */}
            </div>
        </div>
    );
};

export default TestCases;
