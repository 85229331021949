import { useCallback, useEffect, useState } from "react";
import { BACKEND_URL } from "../constants";
import ProblemsMiddleware from "../problems/middleware/middleware";
import { Problem } from "../problems/interfaces";

const problemMiddleware = new ProblemsMiddleware(BACKEND_URL);

export const useProblems = () => {
    const [problems, setProblems] = useState<Problem[]>([]);
    const [selectedProblem, setSelectedProblem] = useState<Problem>();

    useEffect(() => {
        const getProblems = async () => {
            const problems = await problemMiddleware.getProblems();
            setProblems(problems);
        };
        getProblems();
    }, []);

    const selectProblem = useCallback(async (problemId: string) => {
        const problem = await problemMiddleware.getProblem(problemId);
        setSelectedProblem(problem);
    }, []);

    return { selectedProblem, problems, selectProblem };
};
