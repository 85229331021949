import { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import "react-reflex/styles.css";
import { useNavigate, useParams } from "react-router";
import CodeEditor from "../../components/CodeEditor/CodeEditor";
import Debug from "../../components/Debug/Debug";
import ClearButton from "../../components/Debug/DebuggingControls/ClearButton";
import DebuggingControls from "../../components/Debug/DebuggingControls/DebuggingControls";
import ProblemDescription from "../../components/Problem/ProblemDescription/ProblemDescription";
import RunButton from "../../components/RunButton/RunButton";
import Tabs from "../../components/Tabs/Tabs";
import TestCases from "../../components/TestCases/TestCases";
import TestResults from "../../components/TestResults/TestResults";
import { useDebug } from "../../hooks/useDebug";
import { useProblems } from "../../hooks/useProblems";
import { useRun } from "../../hooks/useRun";
import { TabsLeft, TabsRight } from "../../types/types";
import styles from "./ProblemPage.module.scss";
import ProblemList from "../../components/Problem/ProblemList/ProblemList";
import { useSearchParams } from "react-router-dom";
import VisualizerKeeper from "../../components/Debug/Visualizer/VisualizerKeeper";
import Variables from "../../components/Debug/Variables/Variables";

interface ProblemPageProps {}

const ProblemPage: FC<ProblemPageProps> = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { problemId } = useParams();
    const { problems, selectProblem, selectedProblem } = useProblems();

    const [selectedTabLeft, setSelectedTabLeft] = useState<keyof typeof TabsLeft>(
        (searchParams.get("left") as keyof typeof TabsLeft) || "PROBLEM",
    );
    const [selectedTabRight, setSelectedTabRight] = useState<keyof typeof TabsRight>(
        (searchParams.get("right") as keyof typeof TabsRight) || "TEST_CASES",
    );

    const debug = useDebug();
    const run = useRun();

    const [isRunLoading, setIsRunLoading] = useState(false);

    const onRun = () => {
        setIsRunLoading(true);
    };

    useEffect(() => {
        if (problemId) selectProblem(problemId);
    }, [problemId, selectProblem]);

    const { resetTestResults } = run;
    const { clear } = debug;

    useEffect(() => {
        resetTestResults();
        clear();
    }, [resetTestResults, problemId, clear]);

    // useEffect(() => {
    //     setSelectedTabLeft("PROBLEM");
    // }, [selectedProblem?.id]);

    const changeTab = <T extends Object>(key: string, tab: keyof T) => {
        setSearchParams((prev) => ({ ...prev, [key]: tab }));
    };

    const changeTabLeft = (tab: keyof typeof TabsLeft) => {
        changeTab("left", tab);
    };

    const changeTabRight = (tab: keyof typeof TabsRight) => {
        changeTab("right", tab);
    };
    const left = searchParams.get("left");
    const right = searchParams.get("right");

    useEffect(() => {
        if (left) setSelectedTabLeft(left as keyof typeof TabsLeft);
    }, [left]);

    useEffect(() => {
        if (right) setSelectedTabRight(right as keyof typeof TabsRight);
    }, [right]);

    useEffect(() => {
        console.log("effect", selectedTabLeft, selectedTabRight);
    }, [selectedTabLeft, selectedTabRight]);

    // useEffect(() => {
    //     if (selectedTabLeft !== left || selectedTabRight !== right) {
    //         setSearchParams({ left: selectedTabLeft, right: selectedTabRight });
    //     }
    // }, [selectedTabLeft, selectedTabRight, setSearchParams, left, right]);

    return (
        <div className={styles.ProblemPage}>
            <ReflexContainer orientation="vertical">
                <ReflexElement>
                    <div className={styles.Pane}>
                        <div className={styles.LeftPaneContent}>
                            {(debug.isRunning || !debug.isClear) && (
                                <VisualizerKeeper
                                    refresh={debug.refresh}
                                    onClientAdd={debug.onClientAdd}
                                    structureClients={debug.structureClients}
                                />
                            )}

                            {!debug.isRunning && debug.isClear && (
                                <Tabs<typeof TabsLeft>
                                    className={styles.TabsLeftPane}
                                    selectedTab={selectedTabLeft}
                                    onSelectedTabChange={changeTabLeft}
                                    tabs={TabsLeft}
                                >
                                    {selectedTabLeft === "PROBLEMS" && (
                                        <ProblemList problems={problems} selectedProblemId={selectedProblem?.id} />
                                    )}

                                    {selectedTabLeft === "PROBLEM" && selectedProblem && (
                                        <ProblemDescription
                                            name={selectedProblem.name}
                                            description={selectedProblem.description}
                                        />
                                    )}
                                </Tabs>
                            )}
                        </div>
                    </div>
                </ReflexElement>

                <ReflexSplitter className={styles.Splitter} />

                <ReflexElement>
                    <div className={`${styles.Pane}`}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement>
                                {selectedProblem && (
                                    <CodeEditor
                                        problemId={selectedProblem.id}
                                        problemStartingCode={selectedProblem.starting_code}
                                        isRunLoading={isRunLoading}
                                        isDebugLoading={debug.isLoading}
                                        onRun={onRun}
                                        onStop={debug.stop}
                                        onPause={debug.pause}
                                        isDebugging={debug.isRunning}
                                        lineHighlighted={debug.lineHighlighted}
                                        onSetTimeInterval={debug.setTimeInterval}
                                    />
                                )}
                            </ReflexElement>
                            <ReflexSplitter className={styles.Splitter} />
                            <ReflexElement style={{ overflowX: "hidden" }}>
                                {(debug.isRunning || !debug.isClear) && <Variables variables={debug.variables} />}
                                {!debug.isRunning && debug.isClear && (
                                    <Tabs<typeof TabsRight>
                                        selectedTab={selectedTabRight}
                                        onSelectedTabChange={changeTabRight}
                                        tabs={TabsRight}
                                    >
                                        {selectedProblem && selectedTabRight === "TEST_CASES" && (
                                            <TestCases
                                                isDebugLoading={debug.isLoading}
                                                isDebugging={debug.isRunning}
                                                onDebug={(inputs) => {
                                                    // changeTabLeft("DEBUG");
                                                    debug.debug(inputs, selectedProblem);
                                                }}
                                                selectedProblem={selectedProblem}
                                            />
                                        )}
                                        {selectedProblem && selectedTabRight === "TEST_RESULTS" && (
                                            <TestResults
                                                testResults={run.testCaseResults}
                                                isDebugLoading={debug.isLoading}
                                                isDebugging={debug.isRunning}
                                                onDebug={(inputs) => {
                                                    // changeTabLeft("DEBUG");
                                                    debug.debug(inputs, selectedProblem);
                                                }}
                                                testCases={selectedProblem.test_cases}
                                            />
                                        )}
                                    </Tabs>
                                )}
                            </ReflexElement>
                        </ReflexContainer>
                    </div>
                </ReflexElement>
            </ReflexContainer>
            {debug.isRunning &&
                ReactDOM.createPortal(
                    <DebuggingControls
                        onBack={() => {}}
                        onPause={debug.pause}
                        onStop={debug.stop}
                        isPlaying={debug.isRunning}
                        isLoading={debug.isLoading}
                    />,
                    document.getElementById("run-button-root")!,
                )}

            {!debug.isClear &&
                !debug.isRunning &&
                ReactDOM.createPortal(
                    <ClearButton onClear={debug.clear} />,
                    document.getElementById("run-button-root")!,
                )}

            {!debug.isRunning &&
                debug.isClear &&
                selectedProblem &&
                ReactDOM.createPortal(
                    <RunButton
                        onRun={() => {
                            run.run(selectedProblem);
                            changeTabRight("TEST_RESULTS");
                        }}
                        isLoading={false}
                    />,
                    document.getElementById("run-button-root")!,
                )}

            {/* <ProblemMenu selectedProblem={problem} onProblemChange={setProblem} /> */}
        </div>
    );
};

export default ProblemPage;
