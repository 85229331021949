import { FC } from "react";
import styles from "./ProblemList.module.scss";
import { ProblemDifficulties } from "../../../types/types";
import { Problem } from "../../../problems/interfaces";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

interface ProblemListProps {
    selectedProblemId?: string;
    problems: Problem[];
}

const ProblemList: FC<ProblemListProps> = (props) => {
    const navigate = useNavigate();

    const DIFFICULTY_COLORS = {
        [ProblemDifficulties.EASY]: styles.Easy,
        [ProblemDifficulties.MEDIUM]: styles.Medium,
        [ProblemDifficulties.HARD]: styles.Hard,
    };
    return (
        <div className="flex flex-col gap-3 bg-transparent">
            <Table
                color={"default"}
                shadow="none"
                defaultSelectedKeys={props.selectedProblemId ? [props.selectedProblemId] : undefined}
                selectionMode="single"
                disallowEmptySelection
                onSelectionChange={(e) => {
                    navigate(`/problems/${(e as any).anchorKey}`);
                }}
            >
                <TableHeader>
                    <TableColumn>TITLE</TableColumn>
                    <TableColumn>DIFFICULTY</TableColumn>
                </TableHeader>
                <TableBody>
                    {props.problems.map((problem, idx) => (
                        <TableRow key={problem.id}>
                            <TableCell>{`${idx + 1}. ${problem.name}`}</TableCell>
                            <TableCell>
                                <span
                                    className={`${styles.ProblemDifficulty} ${DIFFICULTY_COLORS[problem.difficulty]}`}
                                >
                                    {problem.difficulty}
                                </span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>

        // <div className={styles.ProblemList}>
        //     <div className={styles.Buttons}>
        //         {props.problems.map((problem, idx) => (
        //             <Link
        //                 key={problem.id}
        //                 to={`/problems/${problem.id}`}
        //                 className={`${styles.ProblemButton} ${props.selectedProblemId === problem.id ? styles.Selected : ""}`}
        //             >
        //                 <span className={styles.ProblemNumber}>{idx + 1}</span>

        //                 <div className={styles.ProblemNameWrapper}>
        //                     <span className={styles.ProblemName}>{problem.name}</span>
        //                     {problem.difficulty === ProblemDifficulties.EASY && (
        //                         <span className={`${styles.ProblemDifficulty} ${styles.Easy}`}>
        //                             {problem.difficulty}
        //                         </span>
        //                     )}
        //                     {problem.difficulty === ProblemDifficulties.MEDIUM && (
        //                         <span className={`${styles.ProblemDifficulty} ${styles.Medium}`}>
        //                             {problem.difficulty}
        //                         </span>
        //                     )}
        //                     {problem.difficulty === ProblemDifficulties.HARD && (
        //                         <span className={`${styles.ProblemDifficulty} ${styles.Hard}`}>
        //                             {problem.difficulty}
        //                         </span>
        //                     )}
        //                 </div>
        //             </Link>
        //         ))}
        //     </div>
        // </div>
    );
};

export default ProblemList;
