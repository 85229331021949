import { FC } from "react";
import styles from "./DebuggingControls.module.scss";
import { Button } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface ClearButtonProps {
    onClear: () => void;
}

const ClearButton: FC<ClearButtonProps> = (props) => {
    return (
        <div className={styles.DebuggingControls}>
            <Button onPress={props.onClear} startContent={<FontAwesomeIcon icon={faXmark} />}>
                Close
            </Button>
        </div>
    );
};

export default ClearButton;
