import React from "react";
import { createRoot } from "react-dom/client";
// import App from "./App";
import "./index.css";
import CodeContextProvider from "./context/code-context/CodeContextProvider";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import ProblemPage from "./pages/ProblemPage/ProblemPage";
import { NextUIProvider } from "@nextui-org/react";
import { useProblems } from "./hooks/useProblems";

const container = document.getElementById("root")!;
const root = createRoot(container);

const RandomProblem = () => {
    const { problems } = useProblems();
    if (problems.length === 0) {
        return <div>Loading...</div>;
    }
    const randomProblem = problems[Math.floor(Math.random() * problems.length)];
    return <Navigate to={`/problems/${randomProblem.id}`} />;
};

const router = createBrowserRouter([
    {
        path: "/problems/:problemId",
        element: <ProblemPage />,
    },
    {
        path: "*",
        element: <RandomProblem />,
    },
]);

root.render(
    <React.StrictMode>
        <NextUIProvider>
            <CodeContextProvider>
                <main className="dark text-foreground bg-background">
                    <RouterProvider router={router} />
                </main>

                {/* <App /> */}
            </CodeContextProvider>
        </NextUIProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
