import React from "react";
import styles from "./Tabs.module.scss";
import Button from "../UI/Button/Button";
import { TabsLeft, TabsRight } from "../../types/types";
import { Tabs as TabsNextUI, Tab as TabNextUI, CardBody, Card } from "@nextui-org/react";

interface TabsProps<Tabs> {
    tabs: Tabs;
    selectedTab: keyof Tabs;
    onSelectedTabChange: (tab: keyof Tabs) => void;
    children: React.ReactNode;
    className?: string;
}

function Tabs<T extends Object>(props: TabsProps<T>) {
    return (
        <div className={`${styles.Tabs} ${props.className || ""}`}>
            <div className={styles.TabsNav}>
                <Card className={styles.Card}>
                    <CardBody>
                        <TabsNextUI
                            aria-label="Tabs variants"
                            variant={"underlined"}
                            selectedKey={props.selectedTab?.toString()}
                            onSelectionChange={(tab) => props.onSelectedTabChange(tab as keyof T)}
                        >
                            {Object.entries(props.tabs).map(([tabKey, tabName]) => (
                                <TabNextUI
                                    key={tabKey}
                                    title={tabName}
                                    // onClick={() => props.onSelectedTabChange(tab)}
                                />
                            ))}
                        </TabsNextUI>
                    </CardBody>
                </Card>
            </div>
            <div className={styles.TabContent}>{props.children}</div>
        </div>
    );
}

export default Tabs;
