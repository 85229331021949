import { FC, useEffect, useState } from "react";
import styles from "./TestResults.module.scss";
import { TestCaseResults } from "../../run/interface/interfaces";
// import { ColorRing } from "react-loader-spinner";
// import Button from "../UI/Button/Button";
import { TestCase } from "../../types/types";
import ReactDOM from "react-dom";
import { Button, Tab, Tabs } from "@nextui-org/react";

interface TestResultsProps {
    testResults: TestCaseResults | undefined;
    testCases: TestCase[];
    onDebug: (inputs: TestCase) => void;
    isDebugging: boolean;
    isDebugLoading: boolean;
}

const TestResults: FC<TestResultsProps> = (props) => {
    const [shownTestCase, setShownTestCase] = useState<TestCase>(props.testCases[0]);
    const [debuggingTestCase, setDebuggingTestCase] = useState<TestCase | undefined>(undefined);

    const debugHandler = (testCase: TestCase) => {
        setDebuggingTestCase(testCase);
        props.onDebug(testCase);
    };

    useEffect(() => {
        if (!props.isDebugging) setDebuggingTestCase(undefined);
    }, [props.isDebugging]);

    if (props.testResults === undefined) {
        return (
            <div className={styles.TestCasesUnavailableMessage}>
                Please run the code to see test results in this tab.
            </div>
        );
    }

    const testCaseResult = props.testResults.find((testResult) => testResult.id === shownTestCase.id);

    return (
        <div className={styles.TestResults}>
            <div className={styles.TopPart}>
                <div className={styles.Buttons}>
                    <Tabs
                        aria-label="Tabs variants"
                        variant={"light"}
                        selectedKey={`${shownTestCase.id}`}
                        onSelectionChange={(testCaseId) =>
                            setShownTestCase(props.testCases.find((testCase) => `${testCase.id}` === `${testCaseId}`)!)
                        }
                    >
                        {props.testCases.map((testCase) => (
                            <Tab
                                key={`${testCase.id}`}
                                title={
                                    <div className={styles.TabTitle}>
                                        Case {testCase.id}
                                        <div
                                            className={`${styles.Dot} ${JSON.stringify(props.testResults?.find((testResult) => testResult.id === testCase.id)?.actual) === JSON.stringify(props.testResults?.find((testResult) => testResult.id === testCase.id)?.expected) ? styles.Green : ""}`}
                                        ></div>
                                    </div>
                                }
                            />
                        ))}
                    </Tabs>

                    {/* {props.testCases.map((testCase) => (
                        <Button
                            className={shownTestCase.id === testCase.id ? styles.Selected : ""}
                            onPress={() => setShownTestCase(testCase)}
                        >
                            
                        </Button>
                    ))} */}
                </div>
                <div>
                    {!props.isDebugging &&
                        ReactDOM.createPortal(
                            <Button className={styles.DebugButton} onClick={() => debugHandler(shownTestCase)}>
                                Visualize Case {shownTestCase.id}
                            </Button>,
                            document.getElementById("visualize-button-root")!,
                        )}

                    {props.isDebugging && !props.isDebugLoading && (
                        <p className={styles.DebuggingText}>
                            Debugging
                            {debuggingTestCase !== undefined &&
                                shownTestCase !== debuggingTestCase &&
                                ` Case ${debuggingTestCase.id}`}
                        </p>
                    )}
                </div>
            </div>
            <div className={styles.TestCase}>
                <div className={styles.Section}>
                    <p className={styles.SectionHeading}>Input</p>
                    <pre>
                        {Object.entries(shownTestCase.inputs).map(
                            ([key, value]) => `${key} = ${JSON.stringify(value)}\n`,
                        )}
                    </pre>
                </div>
                <div className={styles.Section}>
                    <p className={styles.SectionHeading}>Expected</p>
                    <pre>{JSON.stringify(testCaseResult?.expected)}</pre>
                </div>
                <div className={styles.Section}>
                    <p className={styles.SectionHeading}>Actual</p>
                    {testCaseResult?.response_type === "RESULT" && <pre>{JSON.stringify(testCaseResult?.actual)}</pre>}
                    {testCaseResult?.response_type === "EXCEPTION" && (
                        <pre className={styles.ExceptionResult}>{testCaseResult?.actual}</pre>
                    )}
                </div>
            </div>
            {/* {props.testResults.map((testResult) => (
                <div className={styles.TestResult}>{}</div>
            ))} */}
        </div>
    );
};

export default TestResults;
