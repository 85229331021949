import { Variables } from "../debug/interfaces/PlayPlanInterface";
import StructureClient from "../debug/structures/structure-clients/StructureClient";
import { Problem } from "../problems/interfaces";

export type id = number;
declare module "*.scss";

export type TestCase = { id: number; inputs: { [varName: string]: any } };

export const TabsLeft = {
    PROBLEMS: "PROBLEMS",
    PROBLEM: "PROBLEM",
    // DEBUG: "DEBUG",
} as const;

// export const TabsLeftLinks = {
//     PROBLEMS: "/problems",
//     PROBLEM: "/",
//     DEBUG: "/debug",
// } as const;

export const TabsRight = {
    TEST_CASES: "TEST CASES",
    TEST_RESULTS: "TEST RESULTS",
} as const;

export enum ProblemDifficulties {
    EASY = "EASY",
    MEDIUM = "MEDIUM",
    HARD = "HARD",
}

export interface OutletContext {
    selectedProblem?: Problem;
    debug?: {
        refresh: () => void;
        onClientAdd: (client: StructureClient) => void;
        structureClients: StructureClient[];
        variables: Variables | undefined;
    };
    problems: Problem[];
}
